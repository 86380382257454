<template>
    <main class="page-container">
        <div v-if="isDraft" class="draft-warning-banner">
            <a-glyph name="attention2" />
            <span>THIS IS A DRAFT LINK: DO NOT publish or share externally.</span>
            <a-glyph name="attention2" />
        </div>

        <div v-if="!isDraft" class="ribbon-placeholder" :class="{ 'crit-hidden': isMounted || !showRibbon }">
            <div v-loading="true" class="loading" el-loading-size="24" />
        </div>

        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <s-ribbon-container :class="{ 'crit-hidden': !isMounted || isLocaleSelectorShown || !showRibbon }" :ribbons="ribbons" />
        <div
            v-loading="true"
            class="page-loading"
            :class="{'crit-hidden': !pageLoading }"
            el-loading-size="48"
        />
        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>

        <template v-if="showPopup && isMounted">
            <s-popup-container :popups="popups" />
        </template>

        <s-chat v-if="!page.settings?.hide_chat" :locale="locale" />
        <s-demo-modal :is-overlay-close="true" />
        <s-unsupported-browsers />
    </main>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { merge } from 'lodash';
import Vue from 'vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import analytics from '@core/mixins/analytics.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SUnsupportedBrowsers from '@core/slices/pages/unsupported-browsers/unsupported-browsers.vue';
import SChat from '@core/slices/shared/chat/chat.vue';
import SDemoModal from '@core/slices/shared/demo-modal/demo-modal.vue';
import SPopupContainer from '@core/slices/shared/popup/popup-container.vue';
import SRibbonContainer from '@core/slices/shared/ribbon/ribbon-container.vue';
import { resolvePageBodySlices } from '@utils/resolve-nested-slices';
import '@core/styles/sections/pages.pcss';

const WEBSITE_ID_MAIN = 1;

export default {
    name: 'PagesComponent',

    components: {
        AGlyph,
        SChat,
        SDemoModal,
        SPopupContainer,
        SRibbonContainer,
        SUnsupportedBrowsers,
        SLocaleSelectorRibbon,
    },

    directives: {
        Loading,
    },

    mixins: [analytics, localeRibbon],

    serverPrefetch() {
        if (this.isSenseActive) this.$store.dispatch('pages/setLoading', true);
    },

    computed: {
        senseData() {
            return this.$store.state.sense.senseData || null;
        },
        isSenseActive() {
            const senseRedirects = this.page?.settings?.redirectPer6sense || [];
            return senseRedirects?.includes(this.$route.params.locale) || senseRedirects?.includes('all');
        },
        page() {
            return this.$store.state.pages.page;
        },
        isDraft() {
            return !this.page.is_published;
        },
        pageLoading() {
            return this.$store.state.pages?.loading || false;
        },
        slices() {
            const page = this.page.body || [];
            return page.map((item) => {
                const translations = item.data?.translations || {};
                const data = merge({}, item.data, translations);
                return { ...item, data };
            });
        },
        ribbons() {
            return this.$store.state.ribbon.data.filter((r) => r.websiteId === WEBSITE_ID_MAIN);
        },
        showRibbon() {
            return Boolean(this.ribbons.length);
        },
        popups() {
            return this.$store.state.popup.data;
        },
        showPopup() {
            return Boolean(this.popups.length);
        },
    },

    beforeCreate() {
        resolvePageBodySlices(this.$store.state.pages.page.body).forEach((fullname) => {
            const pathname = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/pages/${pathname}/${pathname}.vue`));
        });
    },

    async created() {
        if (process.env.VUE_ENV === 'server') return;
        if (this.isSenseActive) {
            await this.$store.dispatch('sense/getData');
            this.redirectPer6Sense();
        }
    },

    methods: {
        async redirectPer6Sense() {
            if (!this.senseData || !this.senseData?.company?.domain) {
                this.pushGaEvent('default');
                this.$store.dispatch('pages/setLoading', false);
                return false;
            }

            const primaryCheck = await this.primaryCheck(this.senseData.company);
            if (primaryCheck) return this.redirectHandler('sp');

            const secondaryCheckResult = this.secondaryCheck(this.senseData?.scores);
            if (secondaryCheckResult) return this.redirectHandler(secondaryCheckResult);

            const thirdCheckResult = this.thirdCheck(this.senseData.company);
            if (thirdCheckResult) return this.redirectHandler(thirdCheckResult);

            this.pushGaEvent('default');
            this.$store.dispatch('pages/setLoading', false);
            return false;
        },
        async primaryCheck(company) {
            const isSPDomain = await this.checkEmail(company.domain);
            return isSPDomain;
        },
        secondaryCheck(scores) {
            if (!scores) return false;
            const bestMatch = scores.find((el) => el.profile_fit === 'Strong');
            if (!bestMatch) return false;
            if (bestMatch.product === 'backup') return 'classic';
            if (bestMatch.product === 'cloud') return 'sp';

            return false;
        },
        thirdCheck(company) {
            if (!company.industry) return false;
            if (company.industry.includes('Manufacturing')) return 'man';
            if (company.industry.includes('Healthcare') || company.industry.includes('Medical')) return 'health';

            return false;
        },
        redirectHandler(type) {
            if (!type) return false;

            const locale = this.$route.params.locale || 'en-us';
            const redirects = {
                sp: 'lp/cloud/cyber-protect/sem-trial/',
                classic: 'lp/cyber-protect/backup/sem-trial/',
                man: 'industries/backup/manufacturing/?SFDCCampaignID=701Vz000004DetO&',
                health: 'industries/backup/healthcare/?utm_content=sfdc:701Vz000004DgTl:701Vz000004eI7H&',
            };

            if (!redirects[type]) return false;

            const path = `/${locale}/${redirects[type]}`;
            const query = window.location.search;
            if (path === window.location.pathname) return false;

            sessionStorage.setItem('6senseRedirect', type);
            this.pushGaEvent(type);
            window.location.href = path + query;
            return false;
        },
        async checkEmail(domain) {
            const domainToEmail = `validate@${domain}`;
            const { data } = await this.$store.dispatch('events/checkEmailInSpDomain', domainToEmail);
            return data.isSp;
        },
        pushGaEvent(type) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'personalization',
                eventAction: 'redirect',
                eventLabel: type,
                Test_Cell_121: `web_45334.${type}`,
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.page-loading {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 999;
}
.ribbon-placeholder {
    min-height: 80px;

    @media (--viewport-tablet) {
        min-height: 56px;
    }
}

.ribbon-placeholder .loading {
    height: 100%;
}

.page {
    position: relative;
    display: flex;
    flex-direction: column;

    > * {
        flex-shrink: 0;
    }
}
</style>
